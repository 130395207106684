<template>
  <div class="block">
    <div class="block-title">
      <slot></slot>
    </div>
    <div class="image-wrapper">
      <mu-paper class="image-block-paper" v-for="(key, index) in count" :key="index" :z-depth="0">
        <div class="image-block" v-if="formData.photos[index]">
          <div
              class="preview-blurred"
              :style="{ backgroundImage: 'url(' + formData.photos[index].url + ')' }"
          ></div>
          <div class="preview-dimmer"></div>
          <div
              class="preview-contain"
              :style="{ backgroundImage: 'url(' + formData.photos[index].url + ')' }"
          ></div>
          <div class="actions">
            <span class="action delete" @click="removePhotos(index)">{{ $t('uploadImages.delete') }}</span>
          </div>
        </div>
        <mu-circular-progress v-else-if="loading && formData.photos[--index]"
                              class="image-block image-block-progress"></mu-circular-progress>
        <div class="image-block image-block-upload"
             v-else-if="(index == formData.photos.length) || (index == 0) && (index <= count)">
          <div class="sizer"></div>
          <div class="preview-contain">
            <mu-icon :value="photosIcon" color="#57c7ff"></mu-icon>
            <input class="upload-input" type="file" @change="onFileChange" title=""/>
          </div>
        </div>
      </mu-paper>
    </div>
    <span v-if="errors.photos" class="error">{{$t(errors.photos)}}</span>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import _cloneDeep from 'lodash.clonedeep';

export default {
  name: 'upload-photos',
  computed: {
    ...mapState("uploadImageStore", ["image", "uploadedImage", "loading"]),
  },
  props: {
    photos: {
      type: Array,
      default: null
    }
  },
  watch: {
    photos: {
      handler() {
        if (this.photos !== null) {
          this.formData.photos = _cloneDeep(this.photos).map(item => {
            return { url: item }
          });
        }
      }
    },
    "formData.photos": {
      deep: true,
      handler() {
        let photos = this.formData.photos.map(item => item.url);
        this.$emit('photosData', photos)
      }
    },
  },
  data: () => ({
    placeholder: null,
    photosIcon: 'add',
    photosSize: '',
    photosType: null,
    list: [],
    count: 10,
    errors: {
      photos: '',
    },
    formData: {
      photos: []
    }
  }),
  mounted() {
    if (this.photos !== null) {
      this.formData.photos = this.photos.map(item => {
        return {url: item}
      })
    }
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      // this.file = new Image();
      let reader = new FileReader();

      reader.onload = async () => {
        this.photosType = file.type;
        this.returnFileSize(file.size, false);
        if (this.validatePhotos()) {
          this.$store.dispatch("uploadImageStore/uploadImage", file).then(() => {
            // Backend has time limit of N sec, after which it closes the connection
            if(!this.uploadedImage.url){
              this.setError({message_key: "errors.validation.connection_timed_out"});
              return;
            }
            let imageObj = {
              url: this.uploadedImage.url,
              name: file.name,
              size: this.uploadedImage.size,
              type: this.uploadedImage.type
            };
            this.formData.photos.push(imageObj);
          });
        }
      };
      reader.readAsDataURL(file);

    },
    returnFileSize(number) {

      let result;
      if (number < 1024) {
        result = number + 'bytes';
      } else if (number >= 1024 && number < 1048576) {
        result = (number / 1024).toFixed(1) + 'KB';
      } else if (number >= 1048576) {
        result = (number / 1048576).toFixed(1) + 'MB';
      }

      this.photosSize = {
        sizeText: result,
        rawSize: number
      };

    },
    removePhotos(index) {
      this.formData.photos.splice(index, 1);
    },
    validatePhotos() {
      let isImg;
      if (this.photosType) {
        isImg = this.photosType.split('/');
      }
      if (this.photosSize && this.photosSize.rawSize > 10000000) {
        this.setError({message_key: "errors.validation.image_size_too_large"});
        // this.removePhotos(-1);
        this.photosSize = ''
        // this.photosIcon = 'broken_image'
      } else if (isImg && isImg[0] !== 'image') {
        this.setError({message_key: "errors.validation.image_upload_failed"});
        // this.removePhotos(-1);
        // this.photosIcon = 'broken_image'
      } else {
        this.rmErr();
        return true;
      }
    },
    setError(err) {
      this.errors.photos = err.message_key
    },
    rmErr() {
      this.errors.photos = null;
      this.photosSize = ''

    },
  }
}

</script>
<style scoped lang="scss">
  .paper {
    padding: 20px 0;
    text-align: center;
  }

  .block {
    &-title {
      color: rgba(0, 0, 0, 0.38);
      margin-bottom: 1rem;
    }
  }

  .upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.001;
    z-index: 5;
    cursor: pointer;
  }

  .error {
    color: #dd4b39;
    text-align: left;
  }

  .button-wrapper {
    text-align: center;

    .mu-button {
      margin: 8px 8px 8px 0;
      vertical-align: middle;
    }

    a.mu-button {
      text-decoration: none;
    }
  }

  .image-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
  }

  .image-block {
    position: relative;
    width: 57px;
    border-radius: 7px;
    margin: 0 34px 34px 0;

    .preview-blurred,
    .sizer {
      padding-bottom: 100%;
      background-size: 300%;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      z-index: 1;
      border-radius: 7px;
      overflow: hidden;
    }

    .preview-dimmer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.6;
      border-radius: 7px;
      overflow: hidden;
    }

    .preview-contain {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 7px;
      overflow: hidden;
    }

    .actions {
      position: absolute;
      top: 100%;
      left: 0;
      padding-top: 9px;
      font-size: 1.2rem;
      cursor: pointer;

      .action {
        opacity: 0.8;
        margin-right: 1.6rem;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.edit {
          color: #57c7ff;
        }

        &.delete {
          color: #f77;
        }
      }
    }

    &-upload {
      background-color: #f2f2f2;

      .mu-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }

    .title {
      overflow: hidden;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  .flex-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
  }

  .button {
    color: #fff;
    background-color: #3369ff;
  }
</style>


