<template>
  <div class="block relative">
    <div class="block-title">
      <slot></slot>
    </div>
    <mu-paper class="paper demo-paper" :z-depth="0" v-if="!formData.image">
      <mu-ripple class="mu-ripple demo-1">
        <mu-circular-progress v-if="loading" class="demo-circular-progress" :size="36"></mu-circular-progress>
        <div v-else>
          <mu-icon size="66" :value="imageIcon" color="#57C7FF"></mu-icon>
          <input ref="fileUploadInput" class="upload-input" type="file" @click="clearError" @change="onFileChangeMain" title=""/>
        </div>
      </mu-ripple>
    </mu-paper>
    <mu-paper class="paper demo-paper paper image-preview" :z-depth="0" v-else>
      <div class="image-preview-blurred" :style="{ backgroundImage: 'url(' + formData.image + ')' }"></div>
      <div class="image-preview-dimmer"></div>
      <div class="image-preview-contain" @click="passClickIntoInput"
           :style="{ backgroundImage: 'url(' + formData.image + ')' }"></div>
      <input ref="fileUploadInput" class="upload-input" type="file" @click="clearError" @change="onFileChangeMain" title="" />
    </mu-paper>
    <div class="image-actions">
      <span class="image-action image-action-edit" @click="passClickIntoInput">{{ $t('uploadImages.edit') }}</span>
      <span class="image-action image-action-delete" @click="removeImage" v-if="formData.image">{{ $t('uploadImages.delete') }}</span>
    </div>
    <div class="hint">
      <span v-if="error" class="error">
        {{ $t(errorMessage) }} {{ errorType === 'size' ? ' — ' + imageSize.sizeText : '' }}.
        <div v-if="errorType === 'size'">
          {{ $t('uploadFiles.messages.fileSizeDisclaimer', { maxFileSize: humanizeFileSize(FILE_SIZE_LIMIT) }) }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import uploadFilesMixin from "../../../mixins/upload-files.mixin";

const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

export default {
  name: 'upload-org-logo',
  computed: {
    ...mapState("uploadImageStore", ["image", "uploadedImage", "loading"]),
    ...mapGetters({
      loading: 'uploadImageStore/getLoadingPhoto',
    })
  },
  mixins: [uploadFilesMixin],
  props: {
    imageUrl: {
      type: String,
    },
    eventInfo: {
      type: Object
    }
  },
  watch: {
    imageUrl: {
      handler() {
        this.formData.image = this.imageUrl;
      }
    },
    "formData.image": {
      deep: true,
      immediate: true,
      handler() {
        if (this.validateImage()) {
          this.$emit('orgLogoData', this.formData)
        }
      }
    },
  },
  data: () => ({
    placeholder: null,
    imageIcon: 'add',
    imagePhotos: 'landscape',
    imageSize: '',
    photosSize: '',
    imageType: null,
    error: false,
    errorMessage: null,
    errorType: null,
    formData: {
      image: '',
    }
  }),
  mounted() {
    if (this.imageUrl) {
      this.formData.image = this.imageUrl;
    }
  },
  methods: {
    humanizeFileSize(bytes) {
      if (bytes < 1024) {
        return bytes + 'bytes';
      }
      if (bytes >= 1024 && bytes < 1048576) {
        return (bytes / 1024).toFixed(1) + 'KB';
      }
      if (bytes >= 1048576) {
        return (bytes / 1048576).toFixed(1) + 'MB';
      }
      return bytes;
    },
    onFileChangeMain(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0], true);
    },
    createImage(file, from) {
      // this.file = new Image();
      let reader = new FileReader();

      reader.onload = async () => {

        if (from) {
          this.returnFileSize(file.size, true);
          this.imageType = file.type;
          if (this.validateImage()) {
            this.$store.dispatch("uploadImageStore/uploadImage", file).then(() => {
              // Backend has time limit of N sec, after which it closes the connection
              if(!this.uploadedImage.url){
                this.setError({
                  message: this.$t("errors.validation.connection_timed_out"),
                  type: "image"
                });
                return;
              }
              this.formData.image = this.uploadedImage.url;
            });
          }
        }
      };
      reader.readAsDataURL(file);

    },
    returnFileSize(number) {

      let result;
      if (number < 1024) {
        result = number + 'bytes';
      } else if (number >= 1024 && number < 1048576) {
        result = (number / 1024).toFixed(1) + 'KB';
      } else if (number >= 1048576) {
        result = (number / 1048576).toFixed(1) + 'MB';
      }

      this.imageSize = {
        sizeText: result,
        rawSize: number
      };
    },
    removeImage() {
      this.formData.image = '';
      this.imageType = null;
    },
    validateImage() {
      // Bypass validation on page load
      // on page load, we have image url from API,
      // but we don't have imageType, which is populated
      // only after this.createImage()
      // NB. imageType can be an empty string — if FileReader fails to recognize a MIME type
      if(!this.imageType && this.imageType !== ''){
        return true;
      }
      // Mime type and file size validation
      const allowedTypes = this.getAllowedImageFileTypes();
      if(allowedTypes.indexOf(this.imageType) < 0){
        this.setError({
          message: this.$t("errors.validation.image_unsupported_format_promo_logo"),
          type: "image"
        });
        this.removeImage();
        this.imageIcon = 'broken_image';
        return false;
      } else if (this.imageSize && this.imageSize.rawSize > FILE_SIZE_LIMIT) {
        this.setError({
          message: this.$t('errors.validation.image_size_too_large'),
          type: 'size',
        });
        this.removeImage();
        this.imageIcon = 'broken_image';
        return false;
      }

      this.clearError();
      return true;
    },
    setError({ message, type }) {
      this.error = true;
      this.errorMessage = message;
      this.errorType = type;
    },
    clearError() {
      this.error = false;
      this.errorMessage = null;
      this.errorType = null;
      this.imageSize = '';
      this.imageIcon = 'add';
    },
    isLoading() {
      return this.$store.state.uploadImageStore.loading;
    },
    passClickIntoInput() {
      /* Design has the «edit» link under the image uploader. Have to pass click from here into the input. */
      this.$refs.fileUploadInput.click();
    },
    showExternalImage(image_url) {
      this.formData.image = image_url;
    }
  }
}

</script>
<style scoped lang="scss">
  /* Default styles */
  .block-title {
    color: rgba(0, 0, 0, 0.38);
  }

  .mu-ripple {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;

  }


  img {
    display: block;
    width: auto;
    height: 100%;
    margin: auto auto 10px;
  }

  .upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .hint {
    position: absolute;
    left: 0;
    top: calc(100% + 12px);
    color: grey;
    font-size: 12px;
  }

  .error {
    color: #dd4b39;
  }

  .button-wrapper {
    text-align: center;

    .mu-button {
      margin: 8px 8px 8px 0;
      vertical-align: middle;
    }

    a.mu-button {
      text-decoration: none;
    }
  }

  .button {
    color: #fff;
    background-color: #3369ff;
  }

  .image-actions {
    display: none;

    .image-action {
      cursor: pointer;
      opacity: 0.8;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  /* Org logo styles
   * Pass the component class="create-event-org-logo"
   */
  .create-event-org-logo {
    .image-preview {
      width: 56px;
      height: 56px;
      overflow: hidden;
      position: relative;
      background-color: $ew-gray-lightest;
      border-radius: 5px;
      margin-right: 4rem;

      /* blurred edges like in vertical videos */
      &-blurred {
        border-radius: 5px;
        background-size: 320%;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
      }

      /* transparent dimmer */
      &-dimmer {
        border-radius: 5px;
        background-color: #000;
        opacity: 0.25;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      /* preview itself using bg size contain */
      &-contain {
        border-radius: 5px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
      }
    }

    .demo-paper,
    .image-actions {
      display: block;
    }

    .demo-paper {
      background-color: #f0f0f0;
      width: 56px;
      height: 56px;
      border-radius: 5px;
      margin-right: 4rem;

      .mu-icon {
        font-size: 3rem !important;
        text-align: center;
        width: 5rem !important;
        height: 5rem !important;
        line-height: 5rem !important;
        border-radius: 5px;
        background-color: #f0f0f0;
      }
    }

    .image-actions {
      font-size: 1.2rem;
      padding-top: 8px;
      width: 56px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .image-action {
        display: inline-block;
        font-size: 8px;

        &-edit {
          color: #57c7ff;
        }

        &-delete {
          color: #f77;
        }
      }

    }
  }

</style>
